import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { IS_DEV } from '@/constants'
import { createSelectors } from '@/util/create-selectors'

interface IndustryState {
  industry: Industry
  setIndustry: (industry: Industry) => void
}

export enum Industry {
  BEAUTY = 'BEAUTY',
  SUPPLEMENTS = 'SUPPLEMENTS',
}

export const allowedRoutes = [
  '/',
  '/social-media',
  '/social-media/posts',
  '/userProfile',
  '/userProfile/company',
  '/userProfile/profiles-permissions',
  '/request-reports',
  IS_DEV ? '/consumer-insights-v2/trends' : '',
]

const defaultState = {
  industry: Industry.BEAUTY,
}

export const useIndustryStoreBase = create<IndustryState>()(
  persist(
    immer((set) => ({
      ...defaultState,
      setIndustry: (industry) => set({ industry }),
    })),
    {
      name: 'industry',
      storage: createJSONStorage(() => localStorage),
      version: 1,
    }
  )
)

export const useIndustryStore = createSelectors(useIndustryStoreBase)
