import React, { ReactNode, useState } from 'react'
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline'
import { Button, CloseButton, Popover, Tooltip } from '@mantine/core'
import pluralize from 'pluralize'
import { useNavigate } from 'react-router-dom'

import { DataLoader } from '@/components/Loader'
import { useLocationHistory } from '@/contexts/historyContext'
import { useAuth } from '@/hooks/useAuth'
// import usePreviousRoute, { routeAtom } from '@/hooks/usePreviousRoute'
import { cn } from '@/util/classNames'
import { trpc } from '@/util/trpc'
import { AIFilters } from '../market-insights/_components/AIFilters'
import { SelectedFiltersPopover } from '../market-insights/_components/Filters'
import { useMIVars } from '../market-insights/_hook'
import { QuickViewProductCard } from '../product-analysis/_components/QuickViewProductCard'
import { useProductStore } from '../product-analysis/_hook'
import { ProductIdsProvider } from '../product-comparison/_components/ProductIdsContext'
import { GnerateReportModal } from '../request-reports'
import { ComparePopover } from './ComparePopover'
import { SettingsMenu } from './SettingsMenu'

export const SelectedProducts = () => {
  const { products, resetProducts, isLoading } = useProductStore()
  const { vars } = useMIVars()

  const {
    data,
    isLoading: isLoadingData,
    isFetching,
  } = trpc.product_insights.productsDetailImages.useQuery(
    {
      geo: vars.geo,
      p_c_ids: products.slice(0, 9).map((p) => p.id),
    },
    {
      enabled: products.length > 0 && !isLoading,
      keepPreviousData: true,
    }
  )

  const [opened, setOpened] = useState(false)

  return (
    products.length > 0 && (
      <div className={cn('flex items-center space-x-2 text-primary-900')}>
        <Popover
          width={'fit-content'}
          position="bottom-start"
          withArrow
          shadow="md"
          opened={opened}
          onChange={setOpened}
        >
          <Popover.Target>
            <Tooltip label="Click to view your product selection." position="bottom-start">
              <button
                className="text-xs text-primary-900 underline underline-offset-2"
                onClick={() => setOpened(true)}
              >
                {products.length} {pluralize('product', products.length)} selected.
              </button>
            </Tooltip>
          </Popover.Target>
          <Popover.Dropdown>
            <div className="flex max-h-[650px] flex-col space-y-2 overflow-y-auto text-sm">
              <div>
                <div className="flex items-center justify-between">
                  <h2 className="text-lg font-medium text-primary-900">Selected products</h2>
                  <CloseButton onClick={() => setOpened(false)} />
                </div>
                <div className="flex items-center space-x-2 text-primary-500">
                  <p>
                    {products.length} {pluralize('product', 5)} selected
                  </p>
                </div>
              </div>

              <div>
                {isFetching ? (
                  <div className="flex w-[550px] items-center justify-center">
                    <DataLoader className="shrink-0" text="Loading products..." height="188px" />
                  </div>
                ) : (
                  <div className="grid grid-cols-5">
                    {data?.slice(0, 9).map((product, id) => (
                      <div className="m-1.5 w-[98px] shrink-0" key={id}>
                        <QuickViewProductCard product={{ ...product, brand_norm: product.brand }} />
                      </div>
                    ))}
                    {products.length > 9 && (
                      <div className="m-1.5 flex w-[98px] shrink-0 items-center justify-center rounded-md border border-primary-200 text-center text-xs text-primary-500">
                        + {products.length - 9} more
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Popover.Dropdown>
        </Popover>

        <Button
          onClick={() => {
            resetProducts()
          }}
          variant="outline"
          color="white"
          className="!border-primary-900 !p-2 !text-xs !text-primary-900"
          size="sm"
        >
          Clear All
        </Button>
      </div>
    )
  )
}

export const TopBarEcom: React.FunctionComponent<
  React.PropsWithChildren<{ topBarActions?: ReactNode }>
> = ({ topBarActions }) => {
  const { products, isLoading } = useProductStore()

  return (
    <TopBarStrip>
      <div className="flex flex-1 space-x-5">
        <TopBarBack />
        <SelectedProducts />
      </div>
      <div className="flex items-center space-x-2">
        <ComparePopover />
        <AIFilters />
        <SettingsMenu />
      </div>
      <div className="flex flex-1 justify-end ">
        {location.pathname.includes('product-analysis') ? (
          <ProductIdsProvider productIds={products.map((d) => d.id)}>
            <GnerateReportModal reportType="PS__V1">
              {(open) => (
                <button
                  className="mr-2 h-[34px] cursor-pointer rounded-md border border-primary-200 bg-primary-700 px-4 py-2 text-xs font-medium text-white transition-colors duration-150 hover:bg-primary-500 disabled:cursor-not-allowed disabled:bg-primary-100 disabled:text-primary-500"
                  onClick={open}
                  disabled={products.length === 0}
                >
                  Product Summary
                </button>
              )}
            </GnerateReportModal>
          </ProductIdsProvider>
        ) : null}
        <div className="flex items-center space-x-2 rounded-md border border-primary-200">
          <SelectedFiltersPopover /> <div>{topBarActions}</div>
        </div>
      </div>
    </TopBarStrip>
  )
}

export const TopBarStrip = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div
        className={cn(
          'fixed inset-x-0 z-50 flex h-[54px] w-full flex-col justify-center bg-primary-50 border-b border-primary-200',
          'top-[73px]'
        )}
      >
        <div className="max-w-layout flex w-full items-center justify-between">{children}</div>
      </div>
      <div className="h-[54px] w-full"></div>
    </>
  )
}

export const TopBarBack = () => {
  const navigate = useNavigate()
  // usePreviousRoute()
  // const [searchParams, setSearchParams] = useSearchParams()
  // const from = useMemo(() => searchParams.get('from'), [])
  const prev = useLocationHistory()

  return (
    <button
      className="flex items-center text-primary-900"
      onClick={() => {
        // if (from) {
        //   navigate(from)
        //   searchParams.delete('from')
        //   setSearchParams(searchParams)
        //   return
        // }
        navigate([...prev][1] ?? -1)
      }}
    >
      <ArrowNarrowLeftIcon className="mr-2 size-4 " />
      <span className="text-sm font-normal">Back</span>
    </button>
  )
}

export const TopBarContact = () => {
  return (
    <div className="-my-1 flex items-center space-x-2">
      <p className="text-xs">
        Currently enjoying our free version? Unlock the full potential by upgrading!
      </p>
      <button className="rounded-md bg-white px-3 py-1 align-middle text-xs">Contact us 🚀</button>
    </div>
  )
}

export const TopBar = () => {
  const { user } = useAuth()
  return (
    <TopBarStrip>
      <TopBarBack />
      {user?.isFree ? <TopBarContact /> : <div />}
    </TopBarStrip>
  )
}
