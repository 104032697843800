import React, { useEffect, useMemo, useState } from 'react'
import { ClockIcon, ExclamationIcon, TrashIcon } from '@heroicons/react/outline'
import { CogIcon } from '@heroicons/react/solid'
import { Button, Modal, Switch, TextInput, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table'
import dayjs from 'dayjs'
import { ActivityIcon, Circle, XIcon } from 'lucide-react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { SearchMd } from 'untitled-icons'

import { EmptyStatev2 } from '@/components/EmptyState'
import { Loader } from '@/components/Loader'
import { SelectMenu } from '@/components/SelectMenu'
import { Table } from '@/components/Table'
import useDebounce from '@/hooks/useDebounce'
import AlignLeftIcon from '@/icons/AlignLeft'
import CalendarIcon from '@/icons/Calendar'
import ListIcon from '@/icons/List'
import { useIndustryStore } from '@/stores/industry-store'
import { cn } from '@/util/classNames'
import { notifyError } from '@/util/error'
import { days, generateTimeOptions } from '@/util/months'
import { multiSelectFilter } from '@/util/multiselectFilter'
import { notifySuccess } from '@/util/success'
import { trpc } from '@/util/trpc'
import { REPORT_MAP } from './RecentReports'

export const AutomationsModal = () => {
  const [opened, { open, close }] = useDisclosure(false)
  const [search, setSearch] = useState('')
  const debouncedQuery = useDebounce(search, 100)
  const { industry } = useIndustryStore()

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 6,
  })

  const { data, isLoading } = trpc.report.getReportsAutomations.useQuery({
    search: debouncedQuery,
    page: pageIndex,
    limit: pageSize,
    industry,
  })
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    automation_id: false,
    report_info_text: false,
  })

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      { accessorKey: 'automation_id' },
      { accessorKey: 'report_info_text' },
      {
        header: () => (
          <Tooltip
            w={230}
            label={
              <div className="space-y-1">
                <p className="font-semibold">Name</p>
                <p>This column will display the name of the report you provided in your request.</p>
              </div>
            }
          >
            <div className="flex items-center space-x-2">
              <AlignLeftIcon className="size-4" />
              <p>Name</p>
            </div>
          </Tooltip>
        ),
        accessorKey: 'report_name_custom',
        meta: {
          className: 'text-left',
          headerClassName: 'bg-primary-50',
        },
        cell: ({ getValue, row }) => {
          const markdownContent = row.getValue<string>('report_info_text')
          return (
            <Tooltip
              label={
                <div className="markdown">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdownContent}</ReactMarkdown>
                </div>
              }
              position="top-start"
              w={350}
              classNames={{
                tooltip: '!bg-white !px-5 !py-4 !text-black !whitespace-normal drop-shadow-xl',
              }}
            >
              <div
                style={{ textTransform: 'capitalize' }}
                className="truncate text-left text-sm font-normal text-primary-900 "
              >
                {getValue()}
              </div>
            </Tooltip>
          )
        },
      },
      {
        header: () => (
          <Tooltip
            w={230}
            label={
              <div className="space-y-1">
                <p className="font-semibold">Type</p>
                <p>
                  This column will display the type of report, such as &quot;Instagram Report,&quot;
                  etc.
                </p>
              </div>
            }
          >
            <div className="flex items-center space-x-2">
              <ListIcon className="size-4" />
              <p>Type</p>
            </div>
          </Tooltip>
        ),
        accessorKey: 'report_type',
        meta: {
          headerClassName: 'bg-primary-50',
          className: 'text-left',
        },
        cell: ({ getValue, row }) => {
          return <p className="text-primary-700">{REPORT_MAP[getValue()]}</p>
        },
      },
      {
        header: () => (
          <Tooltip
            w={230}
            label={
              <div className="space-y-1">
                <p className="font-semibold">Frequency</p>
                <p>
                  This column shows how often you will receive the report, such as every 7, 14 or 30
                  days.
                </p>
              </div>
            }
          >
            <div className="flex items-center space-x-2">
              <ActivityIcon className="size-4" />
              <p>Frequency</p>
            </div>
          </Tooltip>
        ),
        accessorKey: 'execute_every_x_days',
        size: 150,
        meta: {
          headerClassName: 'bg-primary-50',
          className: 'text-left',
        },
        cell: ({ getValue, row }) => {
          const id = row.getValue<string>('automation_id')
          return <SelectFrequency defaultValue={getValue()} id={id} />
        },
      },
      {
        header: () => (
          <Tooltip
            w={230}
            label={
              <div className="space-y-1">
                <p className="font-semibold">Day</p>
                <p>This column shows the day on which you will receive your reports.</p>
              </div>
            }
          >
            <div className="flex items-center space-x-2">
              <CalendarIcon className="size-4" />
              <p>Day</p>
            </div>
          </Tooltip>
        ),
        accessorKey: 'execute_day_of_week',
        size: 120,
        meta: {
          headerClassName: 'bg-primary-50',
          className: 'text-left',
        },
        cell: ({ getValue, row }) => {
          const id = row.getValue<string>('automation_id')
          return <SelectDay defaultValue={getValue()} id={id} />
        },
      },
      {
        header: () => (
          <Tooltip
            w={230}
            label={
              <div className="space-y-1">
                <p className="font-semibold">Time</p>
                <p>
                  This column shows the time the report starts generating, though you may receive it
                  a few minutes later.
                </p>
              </div>
            }
          >
            <div className="flex items-center space-x-2">
              <ClockIcon className="size-4" />
              <p>Time</p>
            </div>
          </Tooltip>
        ),
        accessorKey: 'execute_time_of_day',
        size: 120,
        meta: {
          headerClassName: 'bg-primary-50',
          className: 'text-left',
        },
        cell: ({ getValue, row }) => {
          const id = row.getValue<string>('automation_id')
          return <SelectTime defaultValue={getValue()} id={id} />
        },
      },
      {
        header: () => (
          <Tooltip
            w={230}
            label={
              <div className="space-y-1">
                <p className="font-semibold">Status</p>
                <p>
                  This column shows the status of your report automation, allowing you to pause or
                  reactivate it.
                </p>
              </div>
            }
          >
            <div className="flex items-center space-x-2">
              <Circle className="size-4" />
              <p>Status</p>
            </div>
          </Tooltip>
        ),
        size: 110,
        accessorKey: 'is_active',
        meta: {
          headerClassName: 'bg-primary-50',
          className: 'text-left',
        },
        cell: ({ getValue, row }) => {
          const status = getValue()
          const id = row.getValue<string>('automation_id')
          return <SwitchState defaultValue={status} id={id} />
        },
      },
      {
        id: 'delete',
        header: () => null,
        meta: {
          headerClassName: 'bg-primary-50',
        },
        size: 50,
        cell: ({ getValue, row }) => {
          const id = row.getValue<string>('automation_id')
          return (
            <div>
              <ConfirmationModal id={id} />
            </div>
          )
        },
      },
    ],
    []
  )

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    state: {
      pagination,
      columnVisibility,
    },
    defaultColumn: {
      size: 200, //starting column size
      minSize: 10, //enforced during column resizing
      maxSize: 500, //enforced during column resizing
    },
    pageCount: data?.pagination.pages ?? -1,
    manualPagination: true,
    filterFns: { multiSelect: multiSelectFilter },
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const total = data?.pagination.total

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        size="80%"
        withCloseButton={false}
        centered
        padding={34}
        styles={{
          body: { padding: '30px 45px' },
        }}
        radius="lg"
        className="relative text-sm"
      >
        <button className="absolute right-8 top-8" onClick={close}>
          <XIcon className="size-5 text-primary-500" />
        </button>
        <div className="space-y-3">
          <h1 className="text-lg font-semibold">Manage your report automations</h1>
          <p className="text-gray-500">
            Report automations are options within specific reports to receive them at scheduled
            intervals, <br></br>such as every 7 days, and currently.{' '}
          </p>
          {/* <div className="flex items-center space-x-1 text-accent-600 hover:underline">
            <PlayIcon className="h-4 w-4 " />
            <p>How can I create report automations?</p>
          </div> */}
          <TextInput
            leftSection={<SearchMd className="ml-1 size-4" />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search for automation..."
            width={'60%'}
          />
          <Table
            table={table}
            height={60 * 6 + 44}
            loading={isLoading}
            emptyStateComponent={
              <EmptyStatev2 message={<p>You haven&apos;t created any report automations yet.</p>} />
            }
          />

          {(total ?? 0) > 0 && (data?.pagination.pages ?? 0) > 1 && (
            <div className="flex items-center gap-2 p-4">
              <Button
                size="xs"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                Prev
              </Button>
              <Button size="xs" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                Next
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Button onClick={open} className="shrink-0 grow space-x-2">
        <CogIcon className="mr-1.5 size-4" /> Manage Report Automations
      </Button>
    </>
  )
}

export const SelectFrequency = ({ id, defaultValue }: { id: string; defaultValue: number }) => {
  const [sort, setSort] = useState('7')
  useEffect(() => {
    setSort(defaultValue.toString())
  }, [defaultValue])

  const utils = trpc.useContext()
  const { mutate, isLoading } = trpc.report.updateReportsAutomations.useMutation({
    onSuccess: (data) => {
      utils.report.getReportsAutomations.invalidate()
    },
    onError: notifyError,
  })

  return (
    <SelectMenu
      options={[
        { label: 'Every 7 days', value: '7' },
        { label: 'Every 14 days', value: '14' },
        { label: 'Every 30 days', value: '30' },
      ]}
      value={sort}
      onChange={(value) => {
        mutate({ id, execute_every_x_days: parseInt(value, 10) })
        return setSort(value)
      }}
      className="h-[36px]"
      width={150}
      popoverBaseProps={{ width: 170 }}
    />
  )
}

export const SelectDay = ({ id, defaultValue }: { id: string; defaultValue: string }) => {
  const [sort, setSort] = useState('FRIDAY')
  useEffect(() => {
    if (!defaultValue) return
    setSort(defaultValue)
  }, [defaultValue])

  const utils = trpc.useContext()
  const { mutate, isLoading } = trpc.report.updateReportsAutomations.useMutation({
    onSuccess: (data) => {
      utils.report.getReportsAutomations.invalidate()
    },
    onError: notifyError,
  })

  return (
    <SelectMenu
      options={days.map((day) => ({ label: day, value: day }))}
      value={sort}
      onChange={(value) => {
        mutate({ id, execute_day_of_week: value })
        return setSort(value)
      }}
      className="h-[36px]"
      width={120}
      popoverBaseProps={{ width: 170 }}
    />
  )
}

export const SelectTime = ({ id, defaultValue }: { id: string; defaultValue: string }) => {
  const [sort, setSort] = useState('08:00:00')
  useEffect(() => {
    if (!defaultValue) return
    setSort(dayjs(defaultValue).format('HH:mm:ss'))
  }, [defaultValue])

  const utils = trpc.useContext()
  const { mutate, isLoading } = trpc.report.updateReportsAutomations.useMutation({
    onSuccess: (data) => {
      utils.report.getReportsAutomations.invalidate()
    },
    onError: notifyError,
  })

  return (
    <SelectMenu
      options={generateTimeOptions()}
      value={sort}
      onChange={(value) => {
        const [hour, minute] = value?.split(':') || []
        const time = dayjs()
          .hour(parseInt(hour))
          .minute(parseInt(minute))
          .second(0)
          .millisecond(0)
          .toISOString()
        mutate({
          id,
          execute_time_of_day: time,
        })
        return setSort(value)
      }}
      className="h-[36px]"
      width={120}
      popoverBaseProps={{ width: 170 }}
    />
  )
}

export const SwitchState = ({ id, defaultValue }: { id: string; defaultValue: boolean }) => {
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    if (!defaultValue) return
    setChecked(defaultValue)
  }, [defaultValue])

  const utils = trpc.useContext()
  const { mutate, isLoading } = trpc.report.updateReportsAutomations.useMutation({
    onSuccess: (data) => {
      utils.report.getReportsAutomations.invalidate()
    },
    onError: notifyError,
  })

  return (
    <div className="flex space-x-2">
      <p
        className={cn(
          'w-fit whitespace-nowrap rounded-md px-2 py-1 text-xs font-medium capitalize',
          checked ? 'bg-green-200' : 'bg-gray-100'
        )}
      >
        {checked ? 'Active' : 'Paused'}
      </p>
      <Switch
        disabled={isLoading}
        checked={checked}
        onChange={(event) => {
          mutate({ id, is_active: event.currentTarget.checked })
          setChecked(event.currentTarget.checked)
        }}
      />
      {isLoading && <Loader className="size-4 border-2" />}
    </div>
  )
}

export const ConfirmationModal = ({ id }: { id: string }) => {
  const [opened, setOpened] = useState(false)

  const utils = trpc.useContext()
  const { mutate, isLoading: isDeleting } = trpc.report.deleteReportsAutomations.useMutation({
    onSuccess: (data) => {
      notifySuccess(
        'Report Automation Deleted.',
        'Report Automation has been successfully deleted.'
      )
      utils.report.getReports.invalidate()
    },
    onError: notifyError,
  })
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        size={444}
        centered
        withCloseButton={false}
        className="relative"
        p={0}
      >
        <button
          className="absolute right-8 top-8"
          onClick={(e) => {
            setOpened(false)
          }}
        >
          <XIcon className="size-5 text-primary-500" />
        </button>
        <div className="mx-auto flex min-h-[350px] max-w-[80%] flex-col justify-center space-y-4 text-center">
          <div className="mx-auto flex items-center justify-center space-x-1 text-center">
            <ExclamationIcon className=" size-12 rounded-full border-[6px] border-red-50 bg-red-100 p-1 text-red-700" />
          </div>
          <h1 className="text-base font-semibold">
            Are you sure you <br /> want to delete this report automation?
          </h1>
          <p className="text-sm text-primary-500">This action can&apos;t be undone!</p>
          <div className="grid grid-cols-2 gap-2">
            <Button
              variant="default"
              onClick={(e) => {
                setOpened(false)
              }}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                mutate({ id })
                setOpened(false)
              }}
              fullWidth
            >
              Delete Report
            </Button>
          </div>
        </div>
      </Modal>
      <button
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setOpened(true)
        }}
        disabled={isDeleting}
      >
        {isDeleting ? (
          <Loader className="size-4 border-2 " />
        ) : (
          <TrashIcon className="size-5 text-gray-900" />
        )}
      </button>
    </>
  )
}
