import React, { useMemo } from 'react'
import { Checkbox } from '@mantine/core'
import { xorBy } from 'lodash'

import { RouterOutput, trpc } from '@/util/trpc'
import { useMIVars } from '../../market-insights/_hook'
import { useProductStore } from '../_hook'

export const QuickViewProductCard = ({
  product,
  disableCompare,
  isNew,
}: {
  product: RouterOutput['product_insights']['allProductData']['items'][number]
  disableCompare?: boolean
  isNew?: boolean
}) => {
  const utils = trpc.useContext()
  const { vars } = useMIVars()
  const p_c_id = useMemo(() => product.p_c_id, [product])

  const { products, isProductSelected, toggleProduct } = useProductStore()

  return (
    <div key={p_c_id} className={`w-full rounded-md border border-primary-200`}>
      <div className="relative w-full p-4 text-center ">
        {isNew && ( // Conditionally render the new indicator
          <div className="absolute left-2 top-2 z-10 rounded-full px-2 text-sm  font-medium text-accent-600">
            *New
          </div>
        )}

        <img
          src={product.image_url ?? '/missing-img.jpeg'}
          alt="product img"
          className="mx-auto size-12"
        />
        <Checkbox
          className="absolute right-2 top-2 z-[21]"
          checked={isProductSelected(p_c_id)}
          onChange={() => {
            toggleProduct(p_c_id)
            utils.product_insights.productsDetails.cancel()
            utils.product_insights.productsDetails.setData(
              {
                geo: vars.geo,
                c2_id: vars.c2_id,
                p_c_ids: products.map((p) => p.id),
              },
              (old) => {
                if (old) {
                  const pcp = xorBy(
                    old,
                    [{ ...product, brand: product.brand_norm }],
                    (o) => o.p_c_id
                  )
                  return pcp
                }
              }
            )
            utils.product_insights.productsDetails.invalidate()
          }}
        />
      </div>
    </div>
  )
}
