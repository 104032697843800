export function invertMap<K extends string | number | symbol, V extends string | number | symbol>(
  obj: Record<K, V>
): Record<V, K[]> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    // Convert key to correct type K
    const typedKey = key as K
    const typedValue = value as V

    // Initialize array if this value hasn't been seen before
    if (!acc[typedValue]) {
      acc[typedValue] = []
    }

    // Add the key to the array of keys for this value
    acc[typedValue].push(typedKey)

    return acc
  }, {} as Record<V, K[]>)
}
