import { FunctionComponent } from 'react'
import { Check, ChevronDownIcon } from 'lucide-react'
import { usePostHog } from 'posthog-js/react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useIGStore } from '@/pages/(home)/social-media/_hooks'
import { allowedRoutes, Industry } from '@/stores/industry-store'
import { cn } from '@/util/classNames'
import { SelectMenu } from '../SelectMenu'

export const IndustrySelector: FunctionComponent<
  React.PropsWithChildren<{
    industry: Industry
    setIndustry: (industry: Industry) => void
    industries: Industry[]
    hasSupplementsAccess: boolean
  }>
> = ({ industry, setIndustry, industries, hasSupplementsAccess }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const posthog = usePostHog()
  const { resetFilters } = useIGStore()

  const resetScrollPosition = () => {
    const mainElement = document.getElementById('main')
    const outletContainer = document.getElementById('scrollable')
    if (mainElement) mainElement.scroll(0, 0)
    if (outletContainer) outletContainer.scroll(0, 0)
  }

  return (
    <p className="border-l-2 border-gray-200 pl-4 text-sm font-medium text-gray-500">
      <SelectMenu
        value={industry}
        options={
          industries?.map((industry) => ({
            label: industry,
            value: industry,
          })) ?? []
        }
        onChange={(v) => {
          setIndustry(v as Industry)
          resetScrollPosition()
        }}
        target={
          <div className="flex size-full items-center space-x-1 rounded-md border border-gray-200 bg-accent-10 px-2 py-1 text-xs  text-accent-600 transition-colors hover:bg-accent-100">
            <span className="capitalize">{industry.toLowerCase()}</span>
            <ChevronDownIcon className="size-4" />
          </div>
        }
        title="Choose your industry:"
        disabled={!hasSupplementsAccess}
        customItem={({ option, isSelected, onClick }) => (
          <button
            key={option.value}
            className={cn(
              'flex w-full items-center space-x-2 rounded-md py-2 pl-3 hover:bg-gray-50',
              isSelected && 'bg-gray-100 font-medium'
            )}
            onClick={() => {
              onClick()

              posthog?.capture('Industry Selector Clicked', { title: option.label })

              resetFilters()

              // return to posts if profile page
              if (location.pathname.startsWith('/social-media/profile'))
                return navigate('/social-media/posts', { replace: true })

              // remove search params if posts page
              if (location.pathname.startsWith('/social-media/posts'))
                return navigate('/social-media/posts', { replace: true })

              // return to home if not allowed route
              if (!allowedRoutes.includes(location.pathname))
                navigate(
                  {
                    pathname: '/',
                    search: '',
                  },
                  { replace: true }
                )
            }}
            disabled={!hasSupplementsAccess}
          >
            <span className="w-4">{isSelected && <Check className="size-4" />}</span>
            {hasSupplementsAccess || option.value === Industry.BEAUTY ? (
              <span className="cursor-pointer capitalize text-primary-900">
                {option.label.toLowerCase()}
              </span>
            ) : (
              <div className="flex flex-col text-start">
                <span className="capitalize text-gray-500">{option.label.toLowerCase()}</span>
                <span className="text-xxs text-gray-500">
                  Want early access? Let us know via chat!
                </span>
              </div>
            )}
          </button>
        )}
        width={75}
        popoverBaseProps={{ width: 265, position: 'bottom-start' }}
      />
    </p>
  )
}
