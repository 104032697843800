import { createSearchParams, Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '@/hooks/useAuth'
import { UserStatus } from '@/util/enums'
import userRoles from '@/util/userRoles'
import { Loader } from '../Loader'

export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const { user, isError, isProfileLoading } = useAuth()

  if (isProfileLoading)
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-white">
        <Loader className="size-36 border-accent-600" />
      </div>
    )

  if (isError)
    return (
      <Navigate
        to={{
          pathname: '/login',
          search: createSearchParams({
            from: location.pathname + location.search + location.hash,
          }).toString(),
        }}
        state={{ from: location }}
      />
    )

  if (user?.status === UserStatus.VERIFIED && location.pathname !== '/register/create-profile') {
    return <Navigate to="/register/create-profile" state={{ from: location }} />
  }

  if (
    user?.status === UserStatus.PROFILE_COMPLETED &&
    location.pathname !== '/register/company' &&
    user?.role === userRoles.superAdmin
  ) {
    return <Navigate to={'/register/company'} state={{ from: location }} />
  }

  return <>{children}</>
}
