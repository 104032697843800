import React, { ReactNode, useMemo, useState } from 'react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { Popover, PopoverProps, TextInput } from '@mantine/core'
import { orderBy } from 'lodash'
import { SearchMd } from 'untitled-icons'

import { cn } from '@/util/classNames'
import { Loader } from './Loader'

interface Option {
  label: string
  value: string
  count?: number
  disabled?: boolean
  [x: string]: any
}

type Props<T = string> = {
  title?: ReactNode
  className?: string
  options: Option[]
  value?: T
  inputLabel?: string
  placeholder?: string
  target?: ReactNode
  onChange?: (value: T) => void
  resetButton?: ReactNode
  error?: string
  label?: string
  required?: boolean
  disableReset?: boolean
  width?: React.CSSProperties['width']
  selectedFirst?: boolean // selected value will be shown first
  searchable?: boolean
  manualSearch?: {
    placeholder?: string
    value: string
    onChange: (value: string) => void
  }
  popoverBaseProps?: PopoverProps
  customItem?: (item: {
    isSelected: boolean
    disabled?: boolean
    option: Option
    id: number
    onClick: () => void
  }) => ReactNode
  disabled?: boolean
  loading?: boolean
  emptyState?: string
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const SelectMenu = ({
  inputLabel,
  placeholder,
  value = '',
  className,
  error,
  label,
  required,
  loading,
  onChange,
  options,
  target,
  width = 210,
  searchable = false,
  manualSearch,
  disabled,
  customItem,
  popoverBaseProps,
  emptyState,
  title,
}: Props) => {
  const labelOption = options.find((o) => o.value === value)?.label
  const [search, setSearch] = useState('')
  const [opened, setOpened] = useState(false)
  const sortByCount = (arr: Option[]) => orderBy(arr, (o) => o?.count, 'desc')

  const filteredOptions = useMemo(() => {
    const searchFiltered = options.filter((s) => new RegExp(search, 'i').test(s?.label ?? s.value))

    return sortByCount(searchFiltered)
  }, [search, options])

  return (
    <div style={{ width, justifyContent: 'space-between' }} className="space-y-1">
      {label && (
        <p className="text-sm font-medium">
          {label} {required && <span className="text-red-600">*</span>}
        </p>
      )}
      <Popover
        shadow="md"
        width={'target'}
        position="bottom-start"
        zIndex={600}
        opened={opened}
        onChange={setOpened}
        {...popoverBaseProps}
      >
        <Popover.Target>
          {target ? (
            <button onClick={() => setOpened((o) => !o)}>{target}</button>
          ) : (
            <button
              className={cn(
                'input h-10 truncate rounded-md bg-white px-3 py-2 text-left text-sm transition-colors duration-150 hover:bg-primary-100',
                disabled && 'pointer-events-none border-gray-200 text-gray-500',
                error && 'border-red-500',
                loading && 'pointer-events-none',
                className
              )}
              type="button"
              style={{ width }}
              onClick={() => setOpened((o) => !o)}
            >
              {inputLabel ??
                labelOption ??
                (placeholder && <span className="text-primary-700">{placeholder}</span>)}
              {!disabled &&
                (loading ? (
                  <Loader className="float-right mt-px size-4 border-2" />
                ) : (
                  <ChevronDownIcon className="float-right mt-px size-4 text-gray-600" />
                ))}
            </button>
          )}
        </Popover.Target>
        <Popover.Dropdown className="scroller-sm space-y-3 text-left text-sm font-normal" p={4}>
          {emptyState && (
            <div className="flex items-center justify-center pt-3 text-center text-sm font-medium text-primary-500">
              <p className="w-52">{emptyState}</p>
            </div>
          )}
          {title && <p className="px-2 pt-3 text-sm font-medium">{title}</p>}
          {(searchable || manualSearch) && (
            <TextInput
              placeholder={manualSearch?.placeholder ?? 'Search'}
              leftSection={<SearchMd className="size-4 text-gray-700" />}
              size="xs"
              value={manualSearch ? manualSearch.value : search}
              onChange={(e) =>
                manualSearch ? manualSearch.onChange(e.target.value) : setSearch(e.target.value)
              }
            />
          )}

          <div className=" max-h-64 space-y-1 overflow-y-auto">
            {filteredOptions.map((option, id) => {
              const isSelected = value === option.value
              const onClick = () => {
                onChange?.(option.value)
                setOpened(false)
              }
              return customItem ? (
                customItem({ isSelected, disabled, option, id, onClick })
              ) : (
                <button
                  type="button"
                  key={id}
                  className={cn(
                    'relative flex w-full items-center rounded-md py-2 pl-10 pr-4 hover:bg-gray-50 ',
                    isSelected && 'bg-gray-50 font-medium',
                    option.disabled && 'pointer-events-none text-gray-500'
                  )}
                  disabled={disabled || option.disabled}
                  onClick={() => {
                    onClick()
                  }}
                >
                  {isSelected && (
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <CheckIcon className="size-5 text-accent-600" />
                    </div>
                  )}
                  <div className={'flex w-full items-center justify-between space-x-2 text-sm'}>
                    <div className="truncate">{option?.label}</div>
                    <div className="flex items-center space-x-1">
                      <p className={isSelected ? 'text-accent-600' : 'text-gray-500'}>
                        {option?.count}
                      </p>
                    </div>
                  </div>
                </button>
              )
            })}
          </div>
        </Popover.Dropdown>
      </Popover>
      {error && <p className="text-xs text-red-500">{error}</p>}
    </div>
  )
}
